$theme-colors: (
  primary:         #000637,
  secondary:       #bbbbf2,
  success:         #5ed6b3,
  info:            #1998ff,
  warning:         #ffad00,
  danger:          #ff0000,
  light:           #fc7341,
  dark:            #221e1e,
);

$text-muted: #6e6e6e;

///////// BUTTONS ////////

$btn-padding-y: 0.625rem;
$btn-padding-x: 1.25rem;
$btn-font-size: 0.875rem;
$btn-border-radius: .1875rem;


///////// BUTTONS ////////
.navbar {
  background-color: #f7f8fa;
  padding: 49px 0px 63px 0px;
  .navbar-toggler {
    line-height: inherit;
    .mdi-menu {
      font-size: 30px;
    }
    .mdi-close {
      font-size: 20px;
    }
  }
  .navbar-menu-wrapper {
    .navbar-nav {
      .btn-contact-me {
        margin-left: 148px;
        white-space: nowrap;
        @media (max-width: 992px) {
          margin-left: 0px;
        }
      }
      .nav-link {
        font-size: 0.9375rem;
        padding: 0px 17px;
        font-weight: 500;
        color: #111111;
        &:hover {
          color: #ff0000;
          transition: all 0.3s ease;
        }
        &.active {
          color: #ff0000; 
        }
      }
    }
  }
  .navbar-collapse-logo {
    display: none;
  }
}


@media (max-width: 992px) {
  .navbar {
    .navbar-menu-wrapper {
      .navbar-nav {
      .nav-link {
        font-size: 16px;
      }
      }
      &.navbar-collapse {
        position: fixed;
        top: 0;
        right: 0;
        background: white; 
        z-index: 20;
        height: 100vh;
        padding: 50px 0px;
        width: 250px;
        transform: translateX(100%);
        transition: transform 0.25s ease-in-out;
        &.show {
          transform: translateX(0);
        }
        ul {
          li {
            padding: 10px 10px;
          }
        }
      }
    }
    .navbar-collapse-logo {
      display: block;
    }
  }
}






body {
  font-family: 'Mulish', sans-serif;
  margin: 0px;
  color: #000000;
  &.body-top-padding {
    padding-top: 167px;
  }
  a:hover {
    text-decoration: none;
  }
  &.sidebar-open {
    .fixed-top {
      background-color: transparent;
      .navbar-brand-wrapper {
        img {
          display: none;
        }
      }
    }
    &::before {
      position: fixed;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
}

.banner {
  background-color: #f7f8fa;
  text-align: center;
}

.content-wrapper {
  padding: 27px 0px;  
}

// SECTION-2
.services-overview {
  .content-header {
    padding: 78px 0px 48px 0px;
    text-align: center;
    @media (max-width: 992px) {
      padding: 60px 0px;
    }
  }
  .services-width {
    flex-basis: 24%;
    @media (max-width: 767px) {
      flex-basis: 100%;
    }
  }
  .img-icons {
    transition: all 0.4s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
}

// SECTION-3
.advantages {
  @media (max-width: 992px) {
    .flex-item:nth-of-type(1) { order: 2; }
    .flex-item:nth-of-type(2) { order: 1; }
    .img-digital {
      text-align: center;
    }
  } 
}

// SECTION-6
.contact-me {
  .contact-me-bgimage {
    background-image: url('../images/gdpr.jpg');
    background-position:center;
    background-repeat:no-repeat;
    background-size:cover;
    text-align: center;
    padding: 53px 0px 38px 0px;
  }
}

// SECTION-7
.contact-details {
  .mdi {
    font-size: 14px;
    color: #6e6e6e;
    border-radius: 50px;
    border: 1px solid rgba(151, 151, 151, 0.24);
    padding: 3px 6px;
    margin-right: 10px;
    transition: all 0.6s ease-in-out;
    &:hover {
      background: #a6a6a6;
      color: white;
    }
  }
  a {
    color: $text-muted;
    transition: all 0.6s ease-in-out;
    &:hover {
      color: #ff0000;
    } 
  }
}

footer {
  a {
    color: #ff0000;
    &:hover {
      color: black;
    }
  }
}

// COMMON
.section-subtitle, .customer-designation {
  font-weight: normal;
}

// SCROLL - SMALL HEADER
.header-small {
    .navbar {
      background: white;
      padding: 18px 0px 18px 0px;  
      -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.2);
      -moz-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.2);
      box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.2);
      &.fixed-top {
        top: -50px;
        margin-top: 50px;
        transition: margin-top 1s ease;
      }
      .btn {
        @media (min-width: 992px) {
          padding: 5px 10px;
          margin-left: 20px;
        }
      }
    }
}

// CONTAINER
.container {
  @media (max-width: 575px) {
    padding: 0px 30px;
  }
} 




  
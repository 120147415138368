// Fonts
@import "https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700&display=swap";

@import "variables";
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "typography";
@import "content";
@import "navbar";
@import "utilities";



//Mixins
@import "mixins/buttons";

// Components
@import "components/buttons";
@import "components/forms";



